<template>
	<div>
		<NavHeader></NavHeader>
		<div class="login_page flex_column">
			<div class="content"  :class="language == 'en' ? 'en_style3': ''">
				<div class="mid flex_row">
					<div class="text" :class="language == 'en' ? 'en_style1': ''">
						<span class="justify" :class="language == 'en' ? 'en_style2': ''">{{$t('registered.account')}}</span>:</div>
					<el-input class="flex_1" ref="account" @clear="hiddenCode" @change="checkAccout" :placeholder="$t('common.please')+$t('registered.account1')" v-model="check_form.account"
						size="mini" clearable></el-input>
				</div>
				<div class="mid flex_row">
					<div class="text"  :class="language == 'en' ? 'en_style1': ''">
						<span class="justify" :class="language == 'en' ? 'en_style2': ''">{{$t('registered.realname')}}</span>:</div>
					<el-input type="text" class="flex_1" :placeholder="$t('common.please')+$t('registered.realname')"
						v-model="check_form.realname" size="mini" clearable></el-input>
				</div>
				<div class="mid flex_row">
					<div class="text"  :class="language == 'en' ? 'en_style1': ''">
						<span class="justify"  :class="language == 'en' ? 'en_style2': ''">{{$t('registered.password')}}</span>:</div>
					<el-input  type="password" class="flex_1" show-password :placeholder="$t('common.please')+$t('registered.password')"
						v-model="check_form.password" size="mini" clearable></el-input>
				</div>
				<div class="mid flex_row">
					<div class="text"  :class="language == 'en' ? 'en_style1': ''">
						<span class="justify"  :class="language == 'en' ? 'en_style2': ''">{{$t('registered.agPassword')}}</span>:</div>
					<el-input type="password" class="flex_1" show-password :placeholder="$t('common.please')+$t('registered.agPassword')"
						v-model="check_form.check_password" size="mini" clearable></el-input>
				</div>
				<div v-if="code == 1" class="mid flex_row">
					<div class="text"  :class="language == 'en' ? 'en_style1': ''">
						<span class="justify"  :class="language == 'en' ? 'en_style2': ''">{{$t('registered.code')}}</span>:</div>
					<div class="flex_row flex_1">
						<el-input type="nummber" v-model="check_form.code" size="mini" :placeholder="$t('registered.code')" clearable
							style="width: 100%;"></el-input>
						<el-button size="mini" type="warning" @click="get_sms_code" class="jinBtn"
							style="margin-left: 5px; width: 90%;border: none;">{{ vcodeBtnName }}</el-button>
					</div>
				</div>
				<div v-if="code == 2" class="mid flex_row">
					<div class="text"  :class="language == 'en' ? 'en_style1': ''">
						<span class="justify"  :class="language == 'en' ? 'en_style2': ''">{{$t('registered.code')}}</span>:</div>
					<div class="flex_row flex_1">
						<el-input type="nummber" v-model="check_form.code" size="mini" :placeholder="$t('registered.code')" clearable
							style="width: 100%;"></el-input>
						<el-button size="mini" type="warning" @click="get_email_code" class="jinBtn"
							style="margin-left: 5px; width: 90%;border: none;">{{ vcodeBtnName }}</el-button>
					</div>
				</div>
				<div class="mid flex_row">
					<div class="text"></div>
					<div class="flex_1 cursor flex_row" style="color: #a4a498;justify-content: space-between; align-items: flex-start;">
						<div @click="$router.push('/login')">{{$t('registered.hasAccount')}}</div>
					</div>
				</div>
				<div class="mid flex_row" style="height: 50px; justify-content: center">
					<el-button size="mini" class="jinBtn" style="border: none;" @click="doRegister" type="warning">
						{{$t('common.submit')}}
					</el-button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import NavHeader from '../components/NavHeader.vue';
	import { mapState } from 'vuex';
	import {
		register,
		sendSms,
		emailEvn
	} from '../api/api';
	export default {
		name: 'registered',
		data() {
			return {
				vcodeBtnName: this.$t('registered.sendCode'),
				countNum: 120,
				countDownTimer: null,
				code:0,
				check_form: {
					account: '',
					realname: '',
					password: '',
					check_password: '',
					code: '',
				},

				form_phone: {
					"username": "",
					"password": "",
					"phone": "",
					"smscode": ""
				},
				form_email: {
					"email": "",
					"userName": "",
					"loginPassword": "",
					"smscode": ""
				}
			};
		},
		watch:{
			language(){
				this.vcodeBtnName= this.$t('registered.sendCode')
			}
		},
		computed: {
			...mapState(['language'])
		},
		components: {
			NavHeader
		},
		methods: {
			hiddenCode(){
				this.code = 0;
			},
			checkEmail(){
				var rule = [
					{name:"account", checkType : "email", checkRule:"", errorMsg:this.$t('common.formMsg.email')}
				];
				return this.$zuiChecker.check(this.check_form, rule)
			},
			checkPhone(){
				var rule = [
					{name:"account", checkType : "phoneno", checkRule:"", errorMsg:this.$t('common.formMsg.contactPhone')}
				];
				return this.$zuiChecker.check(this.check_form, rule);
			},
			checkAccout(){
				// console.log(this.checkPhone(),this.checkEmail());
				if(this.checkPhone()){
						this.code = 1;
						return true;
				}
				if(this.checkEmail()){
						this.code = 2;
						return true;
				}
				//账号不属于 手机 或者 邮箱
				this.$message({
					message: this.$t('common.formMsg.account'),
					showClose: true,
					type: 'error',
					customClass:'hahaha',
					duration: 2000,
					offset: 20
				});
				this.$refs.account.focus();
			},
			get_sms_code: function() {
				
				if (!this.checkPhone()) {
					this.$message({
						message: this.$t('common.formMsg.contactPhone'),
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						duration: 2000,
						offset: 20
					});
					return false;
				}
				// 手机号码为 :  this.phoneno
				// vcodeBtnName 可以阻止按钮被多次点击 多次发送 return 会终止函数继续运行
				if (this.vcodeBtnName != this.$t('registered.sendCode') && this.vcodeBtnName != this.$t('registered.sendCode1')) {
					return;
				}
				this.vcodeBtnName = this.$t('registered.sendCode2');
				// 与后端 api 交互，发送验证码 【自己写的具体业务代码】
				sendSms({
					mobile: this.check_form.account,
					smsmode: 1
				}).then(res => {
					// console.log(res);
					if (res.success) {
						// 假设发送成功，给用户提示
						this.$message({
							message: this.$t('registered.mesg1'),
							showClose: true,
							type: 'success',
							customClass:'hahaha',
							offset: 20,
							duration: 2000,
							onClose: () => {
								// 倒计时
								this.countNum = 60;
								this.countDownTimer = setInterval(() => {
									this.countDown();
								}, 1000);
							}
						});
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							duration: 2000,
							offset: 20
						});
						this.vcodeBtnName = this.$t('registered.sendCode')
						// console.log(res.message);
					}
				});
			},
			get_email_code(){
				// console.log('email');
				if (!this.checkEmail()) {
					this.$message({
						message: this.$t('common.formMsg.email'),
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						duration: 2000,
						offset: 20
					});
					return false;
				}
				// 手机号码为 :  this.phoneno
				// vcodeBtnName 可以阻止按钮被多次点击 多次发送 return 会终止函数继续运行
				if (this.vcodeBtnName != this.$t('registered.sendCode') && this.vcodeBtnName != this.$t('registered.sendCode1')) {
					return;
				}
				this.vcodeBtnName = this.$t('registered.sendCode2');
				// 与后端 api 交互，发送验证码 【自己写的具体业务代码】
				emailEvn['sendCode']({email:this.check_form.account}).then(res=>{
					if (res.success) {
						// 假设发送成功，给用户提示
						this.$message({
							message:this.$t('registered.mesg2'),
							showClose: true,
							type: 'success',
							customClass:'hahaha',
							offset: 20,
							duration: 2000,
							onClose: () => {
								// 倒计时
								this.countNum = 60;
								this.countDownTimer = setInterval(() => {
									this.countDown();
								}, 1000);
							}
						});
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass:'hahaha',
							duration: 2000,
							offset: 20
						});
						this.vcodeBtnName = this.$t('registered.sendCode')
						// console.log(res.message);
					}
				})
			},
			countDown: function() {
				if (this.countNum < 1) {
					// clearInterval(this.countDownTimer);
					this.vcodeBtnName = this.$t('registered.sendCode1');
					return;
				}
				this.countNum--;
				this.vcodeBtnName = this.countNum + this.$t('registered.mesg3');
			},
			doRegister() {
				
				//定义表单规则
				var rule = [
					{
						name: "realname",
						checkType: "notnull",
						checkRule: "",
						errorMsg: this.$t('common.formMsg.realname')
					},
					{
						name: "password",
						checkType: "string",
						checkRule: "6,",
						errorMsg: this.$t('common.formMsg.password')
					},
					{
						name: "check_password",
						checkType: "samewith",
						checkRule: "password",
						errorMsg: this.$t('common.formMsg.confirePassword')
					},
					{
						name: "code",
						checkType: "notnull",
						checkRule: "",
						errorMsg: this.$t('common.formMsg.code')
					}
				];
				if (!this.$zuiChecker.check(this.check_form, rule)) {
					this.$message({
						message: this.$zuiChecker.error,
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						duration: 2000,
						offset: 20
					});
					return false;
				}
				//手机注册
				if(this.code == 1){
					// form_phone: {
					// 	"username": "",
					// 	"password": "",
					// 	"phone": "",
					// 	"smscode": ""
					// }
					
					this.form_phone.username = this.check_form.realname
					this.form_phone.phone = this.check_form.account
					this.form_phone.password = this.check_form.password
					this.form_phone.smscode = this.check_form.code
					
					register(this.form_phone).then(res => {
						// console.log(res);
						if (res.code == 200) {
							this.$message({
								message: this.$t('common.formMsg.regSuccess'),
								showClose: true,
								type: 'success',
								customClass:'hahaha',
								offset: 20,
								duration: 2000,
								onClose: () => {
									this.$router.push({
										name: 'login'
									});
								}
							});
						} else {
							this.$message({
								message: res.message,
								showClose: true,
								type: 'error',
								customClass:'hahaha',
								duration: 2000,
								offset: 20
							});
							// console.log(res.message);
						}
					});
				}
				//邮箱注册
				if(this.code == 2){
					// form_email: {
					// 	"email": "",
					// 	"userName": "",
					// 	"loginPassword": "",
					// 	"smscode": ""
					// },
					this.form_email.userName = this.check_form.realname
					this.form_email.email = this.check_form.account
					this.form_email.loginPassword = this.check_form.password
					this.form_email.smscode = this.check_form.code
					
					emailEvn['regEmail'](this.form_email).then(res => {
						// console.log(res);
						if (res.code == 200) {
							this.$message({
								message: this.$t('common.formMsg.regSuccess'),
								showClose: true,
								type: 'success',
								customClass:'hahaha',
								offset: 20,
								duration: 2000,
								onClose: () => {
									this.$router.push({
										name: 'login'
									});
								}
							});
						} else {
							this.$message({
								message: res.message,
								showClose: true,
								type: 'error',
								customClass:'hahaha',
								duration: 2000,
								offset: 20
							});
							// console.log(res.message);
						}
					});
				}
				
			},
		},
		mounted() {}
	};
</script>
<style lang="scss">
	.login_page {
		width: 100%;
		height: 100vh;
		background: url('../assets/image/02.jpg') no-repeat center;
		background-size: cover;

		.content {
			margin-bottom: 100px;
			width: 320px;
			color: #000000;
	.en_style1{
		width: auto !important;
	}
	.en_style2{
		width: 180px !important;
		text-align-last: right !important;
		vertical-align: middle;
	}
	.en_style3{
		//width: 420px !important;
	}
			.mid {
				margin: 10px 0;
				justify-content: flex-start;

				.text {
					font-size: 14px;
					color: #000000;
					width: 120px;
					text-align: right;
					margin-right: 10px;
				}
				.justify{
					display: inline-block;
					width: 60px;
					text-align-last: justify;
				}
			}
		}
	}
	// 手机适配
		@media screen and (max-width: 960px) {
		.login_page {
		.content{
			width: 98% !important;
		

		.en_style2{
		width:100px !important;
			}
			}
			}
		}
</style>
